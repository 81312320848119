.sidepanel {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    width: 20%;
    z-index: 1029;
    background-color: #1a1f71;
    position: fixed;
    height: 100%;
    overflow: auto;
}

.mobile-link {
    display: none;
}

.sidepanel li {
    padding-top: 2.5em;
}

.sidepanel .active {
    color: #faaa13;
}

@media(max-width:767px) {
    .sidepanel {
        width: 100%;
    }
    .mobile-link {
        display: inline-block;
    }
}

.link {
    color: #FFF;
    text-decoration: none !important;
    cursor: pointer;
    margin: 10%;
}

.link:hover {
    color: #fdbb0a;
}
