.animated-logo {
  font: 400 1em/1.5 "Neuton" !important;
  /* background: #fdbb0a; */
  color: rgba(255, 255, 255, 0.25);
  text-align: center;
  margin: 0;
}
.auth {
  background: url(https://i.imgur.com/IrYJgGd.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) { 
  .auth{
    background: url(https://i.imgur.com/AgNmeb5.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.animated-logo p {
  letter-spacing: 0.5em;
  display: inline-block;
  border: 4px double rgba(255, 255, 255, 0.25);
  border-color: #75787b;
  border-width: 4px 0;
  width: 80%;
  color: #1a1f71;
}
.animated-logo span {
  font: 700 4em/1 "Oswald", sans-serif !important;
  letter-spacing: 0;
  padding: 0.25em 0 0.325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);

  /* Clip Background Image */

  background: url(https://i.imgur.com/oPbaVA0.gif) repeat-y;
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  animation: aitf 80s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

/* Animate Background Image */

@keyframes aitf {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
