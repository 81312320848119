.calculator .col-12 .py-5{
    background-color: white;
    padding: 20px;
    border: 2px black solid;
    border-radius: 500px;
}
.ques{
    color: white;
}
.addsupplier{
    background-color: darkgreen;
    color: black;
}
#Login , #signup{
    color: #1a1f71;
    font-weight: 700;
}
#visa , #log {
    color: #1a1f71;
}
#margin-left{
    margin-left: 120px;
     
}
#supplier{
    text-align: center;
    font-size: 30px;
    font-style: italic;
}
#faded{
    background-color: #fdbb0a;
    color: black;
    border-width: 0px;
    float:right;
}
#supply{
    font-size: 23px;
    font-weight: 500;
}
#single{ 
    max-width: 40%;
    
}
 
#shift{
    float: right;
    margin-right: 300px;
}
#next{
    float: right;
    margin-bottom: 40px;
}
#graph{
    background-color: white;
    padding-left: 10px;
    border: black 2px solid;
    
}
#main{
    font-size: 35px;
    font-weight: 500;
    font-style: initial; 
    text-align: center;
    color:#1a1f71;
}
#sub{
    font-size: 25px;
    font-weight: 200;
    font-style: initial; 
    color:#1a1f71;
}
#subover{
    font-size: 29px;
    font-weight: 300;
    font-style: initial; 
    color:#1a1f71;
}
#buttn{
    background-color: ivory;
    border: #1a1f71 1px solid;
    color: #1a1f71;
    float: right;
    font-weight: 500;
}
#graph-style{
    padding: 20px;
}
#check{
    float: right;
}
#forward{
    float: left;
}

#ConvertToBlue{
    color: #FFFFFF;
}
#ring{
    margin-left: 400px;
    margin-top: 120px;
}